import { useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Layout from '../layout/main';
import SEOComponent from '../components/seo';
import Catalog from '../components/firstUnitPage/pages/catalog';
import Powered from '../components/firstUnitPage/pages/powered';
import Elevating from '../components/firstUnitPage/pages/elevating';
import End2End from '../components/firstUnitPage/pages/end2end';
import Compare from '../components/firstUnitPage/pages/compare';
import Reporting from '../components/firstUnitPage/pages/reporting';
import Analytic from '../components/firstUnitPage/pages/analytic';
import ContactUs from '../components/firstUnitPage/pages/contact';
import Feature from '../components/firstUnitPage/pages/feature';
import BgLightFeaturePowered from '../components/firstUnitPage/assets/images/bg-feature-powered-light.png';
import { ContainerWithBg } from '../components/firstUnitPage/styleComponents';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';
import 'swiper/css/effect-fade';
import 'swiper/css/effect-coverflow';
import 'react-photo-view/dist/react-photo-view.css';
import '../components/firstUnitPage/index.css';
import '../components/firstUnitPage/pages/reporting/index.css';
import '../components/firstUnitPage/pages/catalog/index.css';

const GlobalStyle = createGlobalStyle`
  :root {
    --firstUnit-lg-container-width: 1200px;
    --firstUnit-primary: #BFA46D;
    --firstUnit-grey-white: #DFDFFE;
    --firstUnit-primary-gradient: linear-gradient(180deg, #E27285 0%, #9874E5 100%);
  }
`;

const Page = styled.div.attrs(props => ({
    className: 'gatsby_firstUnitPage',
}))`
    line-height: 1.5;
    height: 100%;
    background-color: #0c031b;
`;

function FirstUnit() {
    const contactFormRef = useRef<HTMLDivElement>(null);
    const handleScrollIntoView = () => {
        if (contactFormRef.current) {
            contactFormRef.current.scrollIntoView();
        }
    };
    return (
        <Layout menu="FIRST UNIT" isFirstUnit>
            <SEOComponent
                title="1stUnit"
                // image={seo_content.sharing_image}
                // description={seo_content.seo_description}
            />
            <Page>
                <GlobalStyle />
                <Catalog handleClickContact={handleScrollIntoView} />
                <ContainerWithBg
                    $bg={BgLightFeaturePowered}
                    $paddingBottom="0"
                    $paddingTop="0"
                >
                    <Feature />
                    <Powered />
                </ContainerWithBg>
                <Elevating />
                <End2End />
                <Compare handleClickContact={handleScrollIntoView} />
                <Reporting />
                <Analytic />
                <ContactUs ref={contactFormRef} />
            </Page>
        </Layout>
    );
}

export default FirstUnit;
