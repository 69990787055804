import styled from 'styled-components';
import Earth from '../../assets/icons/earth.svg';
import Award from '../../assets/icons/award.svg';
import Flexible from '../../assets/icons/flexible.svg';
import Key from '../../assets/icons/key.svg';
import {
    ContainerWithBg,
    FlexBox,
    MainAreaWrapper,
    SectionTitle,
    TextCentered,
    Spacer,
} from '../../styleComponents';
import MobileBgFeature from '../../assets/images/mobile-bg-feature.png';
import BgFeature from '../../assets/images/bg-feature.png';
import BgLightFeature from '../../assets/images/bg-feature-light.png';
import { device } from '../../device';
import { useIsMobile } from '../../hooks';

const allFeatures = [
    [
        {
            icon: <Earth />,
            feature: '360+',
            featureColor: '#DA728F',
            description:
                ' engaging and dynamic rich media formats designed for both upper and lower-funnel marketing goals',
        },
        {
            icon: <Award />,
            feature: 'Award-winning',
            featureColor: '#B073C7',
            description:
                ' in-house creative team that’s collaborative and aligned with your media strategy',
        },
    ],
    [
        {
            icon: <Flexible />,
            feature: 'Flexible',
            featureColor: '#DA728F',
            description:
                ' and scalable distribution across social, native, display and video',
        },
        {
            icon: <Key />,
            feature: 'Access',
            featureColor: '#B073C7',
            description:
                ' to unit-specific performance reporting with actionable insights',
        },
    ],
];

const FeaturesWrapper = styled(FlexBox)`
    gap: 1.75rem;
`;

const FeatureCard = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid #ffffff33;
    border-radius: 1rem;
    row-gap: 2rem;

    @media ${device.mobile} {
        padding: 1.25rem 1rem;
    }

    @media ${device.laptop} {
        flex: 1;
        padding: 2.5rem 1.5rem 2.5rem 2.5rem;
    }
`;

const RowBox = styled.div<{ $color?: string }>`
    display: flex;
`;

const TextBox = styled.div`
    margin-left: 1rem;
`;

const ColoredText = styled.span<{ $color: string }>`
    color: ${({ $color }) => $color};
    font-size: 28px;
`;

export default function Feature() {
    const isMobile = useIsMobile();

    return (
        <ContainerWithBg
            $bg={isMobile ? MobileBgFeature : BgFeature}
            $paddingBottom={'0'}
            $paddingTop={'0'}
        >
            <ContainerWithBg $bg={BgLightFeature}>
                <MainAreaWrapper>
                    <TextCentered>
                        <SectionTitle>One size does not fit all</SectionTitle>
                    </TextCentered>
                    <Spacer $h={isMobile ? '2rem' : '3.75rem'} />
                    <FeaturesWrapper $alignItems="stretch">
                        {allFeatures.map(features => {
                            return (
                                <FeatureCard key={`row-${features[0].feature}`}>
                                    {features.map(feature => {
                                        return (
                                            <RowBox key={feature.feature}>
                                                <div>{feature.icon}</div>
                                                <TextBox>
                                                    <ColoredText
                                                        $color={
                                                            feature.featureColor
                                                        }
                                                    >
                                                        {feature.feature}
                                                    </ColoredText>
                                                    {feature.description}
                                                </TextBox>
                                            </RowBox>
                                        );
                                    })}
                                </FeatureCard>
                            );
                        })}
                    </FeaturesWrapper>
                </MainAreaWrapper>
            </ContainerWithBg>
        </ContainerWithBg>
    );
}
