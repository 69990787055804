import styled from 'styled-components';
import { device } from '../../device';

export type AudiButtonType = 'colorful' | '';

type Props = {
  readonly value?: string;
  readonly children: React.ReactNode;
  readonly type?: AudiButtonType;
  readonly className?: string;
  readonly onClick?: (value: string) => void;
  readonly style?: React.CSSProperties;
};

const Box = styled.span<{ $isColorful?: boolean }>`
  padding: 0.5rem 2rem;
  cursor: pointer;
  border-radius: 1rem;
  border: ${({ $isColorful }) => ($isColorful ? 'none' : '1px solid #dfdffe33')};
  background: ${({ $isColorful }) => ($isColorful ? 'var(--firstUnit-primary-gradient)' : 'transparent')};

  @media ${device.mobile} {
    padding: 0.5rem 1.25rem;
    border-radius: 0.625rem;
  }
`;

export default function AudiButton({ value, type, children, className, style, onClick }: Props) {
  return (
    <Box
      onClick={() => onClick?.(value ?? (children as string))}
      onKeyUp={() => onClick?.(value ?? (children as string))}
      className={className}
      style={style}
      $isColorful={type === 'colorful'}
    >
      {children}
    </Box>
  );
}
