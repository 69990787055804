import React, { useState } from 'react';
import styled from 'styled-components';
import {
    TextCentered,
    Centered,
    SectionTitle,
    PrimaryText,
    ContainerWithBg,
    MainAreaWrapper,
    FlexBox,
    FlexItem,
    SubTitle,
} from '../../styleComponents';

import { useIsMobile } from '../../hooks';
import { device } from '../../device';
import MobileElevatingBg from '../../assets/images/mobile-bg-elevating.png';
import MobileElevatingDock from '../../assets/images/mobile-elevating-dock.png';

import elevatingPhone from '../../assets/images/elevating-phone.png';
import elevatingPhoneDock from '../../assets/images/bg-elevating-dock.png';
import elevatingBg from '../../assets/images/bg-elevating.png';

import TriangleActive from '../../assets/icons/elevating-triangle-active.svg';
import Triangle from '../../assets/icons/elevating-triangle.svg';
import People from '../../assets/icons/elevating-people.svg';
import PeopleActive from '../../assets/icons/elevating-people-active.svg';
import Expand from '../../assets/icons/elevating-expand.svg';
import ExpandActive from '../../assets/icons/elevating-expand-active.svg';
import Box from '../../assets/icons/elevating-box.svg';
import BoxActive from '../../assets/icons/elevating-box-active.svg';
import Pause from '../../assets/icons/elevating-pause.svg';
import PauseActive from '../../assets/icons/elevating-pause-active.svg';
import Computer from '../../assets/icons/elevating-computer.svg';
import ComputerActive from '../../assets/icons/elevating-computer-active.svg';
import Star from '../../assets/icons/elevating-stars.svg';
import StarActive from '../../assets/icons/elevating-stars-active.svg';
import realCarousel from '../../assets/gifs/real/real-carousel.gif';

type Brand = {
    id: number;
    icon: React.ReactNode;
    activeIcon: React.ReactNode;
    title: string;
    description: string;
};

const brands: Brand[] = [
    {
        id: 1,
        icon: <Triangle />,
        activeIcon: <TriangleActive />,
        title: 'Bespoke Creative',
        description:
            'Our award-winning team of creative strategists are on-hand to conceptualize, design and build bespoke creative assets that are tailored to your audiences and the places, devices and platforms on which they engage.',
    },
    {
        id: 2,
        icon: <People />,
        activeIcon: <PeopleActive />,
        title: 'Cookieless Data Activation',
        description:
            'Audigent powers access to powerful cookieless first-party, contextual, and cognitive (predictive) data assets for each campaign, ensuring your brand’s messaging is reaching your target audience across key channels.',
    },
    {
        id: 3,
        icon: <Expand />,
        activeIcon: <ExpandActive />,
        title: 'Premium Inventory at Scale',
        description:
            'Audigent’s strategy and trading teams work with you to create comprehensive media plans that reach your audience wherever they are – open exchange, YouTube, social platforms, programmatic audio, CTV and more.',
    },

    {
        id: 4,
        icon: <Computer />,
        activeIcon: <ComputerActive />,
        title: 'Every Channel, Every Device',
        description:
            'Reach your target audience with engaging content on the platforms and devices that matter - from social and native to display and online video.',
    },
    {
        id: 5,
        icon: <Pause />,
        activeIcon: <PauseActive />,
        title: 'Comprehensive Reporting and Insights',
        description:
            'We believe better audience data delivers better campaign results. Your dedicated reporting and analytics team monitors audience composition, supply sources and creative versioning in real-time to drive optimizations and boost ROI.',
    },
    {
        id: 6,
        icon: <Box />,
        activeIcon: <BoxActive />,
        title: 'White Glove Customer Service',
        description:
            'Support and experience matter. That’s why we’ve brought together full-service audience strategy, media planning and creative development resources under one umbrella to deliver connected + strategic solutions for the brands we support. ',
    },
];

const OutlinedCard = styled.div<{ $width?: string }>`
    display: flex;
    flex-direction: column;
    padding: 29px;
    border-radius: 24px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 0px 9px rgba(255, 255, 255, 0.03) inset;
    backdrop-filter: blur(17px);
    text-align: left;

    @media ${device.mobile} {
        justify-content: normal;
        align-items: stretch;
    }
`;

const ImgWrapper = styled.div`
    margin: 0 2.5rem;
    position: relative;

    @media ${device.mobile} {
        width: auto;
        margin: 3rem auto 0;
    }
`;

const CreativeBox = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 24px);
    height: calc(100% - 19px);
    margin: 10px 12px;
    border-radius: 2rem;
    overflow: hidden;
`;

const CreativeImg = styled.img`
    object-fit: cover;
`;

const SideBox = styled(FlexBox)`
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
`;
const IconBox = styled.div`
    width: 32px;
`;

const BrandTitle = styled.div`
    margin-left: 0.75rem;
    font-size: 1rem;
    color: white;
    font-family: 'Renogare-Regular';
`;

const CollapseButton = styled.div`
    margin-left: 0.75rem;
    min-width: 8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    @media ${device.mobile} {
        min-width: 5rem;
    }
`;

const DescriptionBox = styled(FlexBox)`
    margin-top: 1rem;
    line-height: 1.3;
`;

const RectFlag = styled.div`
    width: 0.25rem;
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.24);
`;

const PackUpText = styled.span`
    font-size: 14px;
    color: white;
`;

const UnfoldText = styled.span`
    font-size: 14px;
    background: var(--firstUnit-primary-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export default function Elevating() {
    const isMobile = useIsMobile();
    const [activeCardId, setActiveCardId] = useState(1);

    const commonTitlePart = () => (
        <TextCentered>
            <Centered $column>
                <SectionTitle>
                    Elevating brands with{isMobile && <br />}{' '}
                    <PrimaryText>cutting-edge creative</PrimaryText>
                </SectionTitle>
                <SubTitle>
                    1stUnit's innovative rich media capabilities seamlessly
                    integrate first-party, contextual, and predictive data with
                    bespoke media plans designed to perform.
                </SubTitle>
            </Centered>
        </TextCentered>
    );

    const renderCardGroup = (data: Brand[]) => {
        return data.map(brand => {
            const active = activeCardId === brand.id;
            return (
                <OutlinedCard key={brand.id}>
                    <FlexBox
                        $flexDirection="row"
                        $justifyContent="space-between"
                    >
                        <FlexBox
                            $flexDirection="row"
                            $alignItems="center"
                            $justifyContent="flex-start"
                        >
                            <IconBox>
                                {active ? brand.activeIcon : brand.icon}
                            </IconBox>
                            <BrandTitle>{brand.title}</BrandTitle>
                        </FlexBox>
                        <CollapseButton
                            onClick={() => {
                                if (active) {
                                    setActiveCardId(-1);
                                } else {
                                    setActiveCardId(brand.id);
                                }
                            }}
                        >
                            {active ? <Star /> : <StarActive />}
                            {active ? (
                                <PackUpText>
                                    {isMobile ? 'Pack up' : 'Collapse details'}
                                </PackUpText>
                            ) : (
                                <UnfoldText>
                                    {isMobile ? 'Unfold' : 'Click for details'}
                                </UnfoldText>
                            )}
                        </CollapseButton>
                    </FlexBox>
                    {active && (
                        <DescriptionBox
                            $flexDirection="row"
                            $alignItems="stretch"
                        >
                            <RectFlag />
                            <FlexItem $flex={1} $margin="0 0 0 0.75rem">
                                {brand.description}
                            </FlexItem>
                        </DescriptionBox>
                    )}
                </OutlinedCard>
            );
        });
    };

    return isMobile ? (
        <ContainerWithBg $paddingTop="0">
            <ContainerWithBg $bg={MobileElevatingBg} $paddingBottom="0">
                <ContainerWithBg
                    $bg={MobileElevatingDock}
                    $bgPosition="bottom"
                    $bgSize="contain"
                    $paddingTop="0"
                    $paddingBottom="146px"
                >
                    <MainAreaWrapper>
                        {commonTitlePart()}
                        <FlexBox>
                            <ImgWrapper>
                                <img
                                    src={elevatingPhone}
                                    style={{ width: '264px' }}
                                    alt="phone image"
                                />
                                <CreativeBox>
                                    <CreativeImg
                                        src={realCarousel}
                                        alt="creative gif"
                                    />
                                </CreativeBox>
                            </ImgWrapper>
                        </FlexBox>
                    </MainAreaWrapper>
                </ContainerWithBg>
            </ContainerWithBg>
            <MainAreaWrapper $margin="1rem auto 0">
                <FlexBox $gap={'1.25rem'} $alignItems="stretch">
                    {renderCardGroup(brands)}
                </FlexBox>
            </MainAreaWrapper>
        </ContainerWithBg>
    ) : (
        <ContainerWithBg $bg={elevatingBg} $paddingBottom="0">
            <MainAreaWrapper
                $bg={elevatingPhoneDock}
                $bgPosition="bottom"
                $bgSize="contain"
                $padding="0 0 200px"
            >
                {commonTitlePart()}

                <MainAreaWrapper $margin="7rem 0 0">
                    <FlexBox $alignItems="stretch">
                        <FlexItem $flex={1}>
                            <SideBox>
                                {renderCardGroup(brands.slice(3, 6))}
                            </SideBox>
                        </FlexItem>
                        <ImgWrapper>
                            <img
                                src={elevatingPhone}
                                width="261"
                                alt="phone image"
                            />
                            <CreativeBox>
                                <CreativeImg
                                    src={realCarousel}
                                    alt="creative gif"
                                />
                            </CreativeBox>
                        </ImgWrapper>
                        <FlexItem $flex={1}>
                            <SideBox>
                                {renderCardGroup(brands.slice(0, 3))}
                            </SideBox>
                        </FlexItem>
                    </FlexBox>
                </MainAreaWrapper>
            </MainAreaWrapper>
        </ContainerWithBg>
    );
}
