import {
    ContainerWithBg,
    MainAreaWrapper,
    Centered,
    SectionTitle,
    PrimaryText,
    FlexItem,
    GridBox,
    TextAlign,
    FlexBox,
    DesignatedMarginDescription,
} from '../../styleComponents';
import BgEnd from '../../assets/images/bg-end.png';
import BgLightEnd from '../../assets/images/bg-end-light.png';
import end1 from '../../assets/images/end_1.png';
import end2 from '../../assets/images/end_2.png';
import end3 from '../../assets/images/end_3.png';
import end4 from '../../assets/images/end_4.png';
import styled from 'styled-components';
import { useIsMobile } from '../../hooks';
import { device } from '../../device';

const solutions = [
    { img: end1, text: 'Fully integrated team' },
    { img: end2, text: 'Cross-channel planning' },
    {
        img: end3,
        text: (
            <span>
                Preeminent media
                <br /> activation
            </span>
        ),
    },
    { img: end4, text: 'Advanced reporting' },
];

//TODO: duplicated with powered
const ContentBox = styled(FlexBox)`
    gap: 1.375rem;

    @media ${device.mobile} {
        gap: 3rem;
    }
`;

const ImgBox = styled.div`
    position: relative;
`;

const ImgDescription = styled.span<{ $paddingTop?: string }>`
    position: absolute;
    top: 0;
    width: 100%;
    color: var(--grey-white);
    font-size: 1.25rem;
    text-align: center;
    opacity: 0.8;
    padding-top: ${props => props.$paddingTop};

    @media ${device.mobile} {
        font-size: 0.625rem;
    }
`;

export default function End2End() {
    const isMobile = useIsMobile();

    const getPaddingTop = (index: number) => {
        if (index === 2) {
            return isMobile ? '0.5rem' : '1.125rem';
        } else {
            return isMobile ? '0.875rem' : '1.75rem';
        }
    };
    return (
        <ContainerWithBg $bg={BgEnd} $paddingBottom={'0'} $paddingTop={'0'}>
            <ContainerWithBg $bg={BgLightEnd}>
                <MainAreaWrapper>
                    <ContentBox>
                        <FlexItem $flex={7}>
                            <TextAlign>
                                <Centered $column>
                                    <SectionTitle>
                                        <PrimaryText>Connected</PrimaryText>
                                        <br /> solutions begin with world class
                                        data capabilities
                                    </SectionTitle>
                                    <DesignatedMarginDescription $margin="1.5rem 4rem 0 0">
                                        Audigent delivers comprehensive, robust
                                        audience strategies, cross-channel media
                                        planning and insight-driven reporting to
                                        deliver efficiency and performance.
                                    </DesignatedMarginDescription>
                                </Centered>
                            </TextAlign>
                        </FlexItem>
                        <FlexItem $flex={10}>
                            <GridBox
                                $gridTemplateColumns="repeat(2, 1fr)"
                                $gridGap={isMobile ? 10 : 20}
                            >
                                {solutions.map((solution, index) => {
                                    return (
                                        <ImgBox key={solution.img}>
                                            <img
                                                src={solution.img}
                                                alt="data capability"
                                            />
                                            <ImgDescription
                                                $paddingTop={getPaddingTop(
                                                    index
                                                )}
                                            >
                                                {solution.text}
                                            </ImgDescription>
                                        </ImgBox>
                                    );
                                })}
                            </GridBox>
                        </FlexItem>
                    </ContentBox>
                </MainAreaWrapper>
            </ContainerWithBg>
        </ContainerWithBg>
    );
}
