import styled from 'styled-components';
import {
    ContainerWithBg,
    TextCentered,
    SubTitle,
    MainAreaWrapper,
    FlexBox,
    GradientPrimary,
    Centered,
    Spacer,
} from '../../styleComponents';

import BgAnalyticLight from '../../assets/images/bg-analytic-light.png';
import BgStar from '../../assets/images/bg-star.png';
import MobileBgAnalytic from '../../assets/images/mobile-bg-analytic.png';
import BgTrapezoid from '../../assets/images/bg-trapezoid.png';
import roundAnalytic from '../../assets/images/bg-analytic-round.png';
import mobileRoundAnalytic from '../../assets/images/mobile-bg-round-analytic.svg';

import Pause from '../../assets/icons/pause.svg';
import People from '../../assets/icons/people.svg';
import Cart from '../../assets/icons/cart.svg';
import Volume from '../../assets/icons/volume.svg';
import { device } from '../../device';
import { useIsMobile } from '../../hooks';

const MainContentBox = styled(FlexBox)`
    margin-top: 4rem;
    height: 800px;

    @media ${device.mobile} {
        gap: 3rem;
        justify-content: flex-start;
        height: auto;
    }
`;

const CircleBox = styled.div`
    position: relative;
    width: 640px;
    height: 640px;
    background-size: 100%;
    background-image: url(${roundAnalytic});
`;

export const Card = styled.div<{ $left?: string; $top?: string }>`
    border-radius: 12px;
    padding: 20px;
    background-color: #ffffff1a;
    background-image: url(${BgTrapezoid});
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);

    position: absolute;
    width: 282px;
    left: ${props => props.$left};
    top: ${props => props.$top};

    @media ${device.mobile} {
        position: relative;
        width: auto;
    }
`;

const CardTitle = styled(GradientPrimary)`
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
    font-family: Renogare-Regular;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const CardMainItem = styled.li`
    margin-top: 16px;
    font-size: 16px;
    color: var(--grey-white);
`;

const CardViceItem = styled.li`
    margin-top: 4px;
    font-size: 14px;
`;

const IconWrapper = styled.div<{
    $left?: string;
    $top?: string;
    $width: string;
    $height: string;
}>`
    position: absolute;
    left: ${props => props.$left};
    top: ${props => props.$top};
    background: linear-gradient(180deg, #e272854c 0%, #9874e54c 100%);
    border-radius: 50%;
    width: ${props => props.$width};
    height: ${props => props.$height};
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${device.mobile} {
        position: relative;
        padding: 0.5rem;
    }
`;

const GradientPie = styled(Centered)`
    font-family: Renogare-Regular;
    border-radius: 50%;
    background: var(--firstUnit-primary-gradient);
    position: absolute;
    text-align: center;
    font-size: 27px;
    color: #f4f2f0;

    width: 256px;
    height: 256px;
    left: calc(50% - 128px);
    top: calc(50% - 128px);

    @media ${device.mobile} {
        width: 38vw;
        height: 38vw;
        left: calc(31vw - 1rem);
        top: calc(31vw - 1rem);
        font-size: 0.875rem;
    }
`;

const MobileRound = styled.div`
    width: 100%;
    height: calc(100vw - 2rem);
    position: relative;
    background-image: url(${mobileRoundAnalytic});
    background-size: 100% 100%;
    background-repeat: no-repeat;
`;

const Ul = styled.ul`
    margin: 0;
    padding: 0 0 0 1.25rem;
`;

const data = [
    {
        head: 'Audience Insights',
        list: [
            'DEMOGRAPHICS',
            'GEOS',
            'INTERESTS & AFFINITIES',
            'WEB BROWSING (Audigent ecosystem) ',
        ],
        left: '-210px',
        top: '60px',
        icon: {
            icon: <Pause />,
            left: '80px',
            top: '60px',
            width: '100px',
            height: '100px',
        },
    },
    {
        head: 'Audience Segment Optimizations ',
        list: [
            'DATA-DRIVEN OPTIMIZATIONS',
            'ENGAGEMENT LEVELS',
            'MODELED AUDIENCES',
        ],
        left: '556px',
        top: '-40px',
        icon: {
            icon: <People />,
            left: '455px',
            top: '95px',
            width: '94px',
            height: '94px',
        },
    },
    {
        head: 'Purchaser Insights ',
        list: [
            [
                'USER JOURNEY TRACKING',
                'Page Exploration Insights',
                'Average Time to Convert',
            ],
            [
                'PURCHASE INTENDER TRACKING',
                'Add to Cart',
                'Checkout Initiation',
            ],
        ],
        left: '-40px',
        top: '392px',
        icon: {
            icon: <Cart />,
            left: '260px',
            top: '510px',
            width: '94px',
            height: '94px',
        },
    },
    {
        head: 'Campaign Optimizations',
        list: [
            'IN-FLIGHT SEGMENT ITERATIONS',
            'SEGMENT REFRESHES',
            'SEGMENT REFINEMENT',
        ],
        left: '486px',
        top: '510px',
        icon: {
            icon: <Volume />,
            left: '520px',
            top: '430px',
            width: '74px',
            height: '74px',
        },
    },
];

export default function Analytic() {
    const isMobile = useIsMobile();

    const renderMobile = () => {
        return (
            <>
                <MobileRound>
                    <GradientPie>
                        DATA
                        <br />
                        SCIENCE <br />
                        &<br />
                        ANALYTICS
                    </GradientPie>
                </MobileRound>
                <FlexBox $gap={'2.5rem'}>
                    {data.map((datum, index) => {
                        return (
                            <FlexBox
                                key={datum.head}
                                $gap={'1.25rem'}
                                $flexDirection={
                                    index & 1 ? 'row-reverse' : 'row'
                                }
                                $alignItems="flex-start"
                            >
                                <IconWrapper $height="3.75rem" $width="3.75rem">
                                    {datum.icon.icon}
                                </IconWrapper>
                                <Card>
                                    <CardTitle>{datum.head}</CardTitle>
                                    <Ul>
                                        {datum.list.map(listItem => {
                                            if (typeof listItem === 'string') {
                                                return (
                                                    <CardMainItem
                                                        key={listItem}
                                                    >
                                                        {listItem}
                                                    </CardMainItem>
                                                );
                                            } else {
                                                return (
                                                    <CardMainItem
                                                        key={listItem[0]}
                                                    >
                                                        {listItem[0]}
                                                        <Spacer
                                                            $h={'0.625rem'}
                                                        />
                                                        <Ul>
                                                            {listItem
                                                                .slice(1)
                                                                .map(
                                                                    viceItem => (
                                                                        <CardViceItem
                                                                            key={
                                                                                viceItem
                                                                            }
                                                                        >
                                                                            {
                                                                                viceItem
                                                                            }
                                                                        </CardViceItem>
                                                                    )
                                                                )}
                                                        </Ul>
                                                    </CardMainItem>
                                                );
                                            }
                                        })}
                                    </Ul>
                                </Card>
                            </FlexBox>
                        );
                    })}
                </FlexBox>
            </>
        );
    };

    const renderDesktop = () => {
        return (
            <CircleBox>
                <GradientPie>
                    DATA
                    <br />
                    SCIENCE <br />
                    &<br />
                    ANALYTICS
                </GradientPie>
                {data.map(datum => {
                    return (
                        <div key={datum.head}>
                            <Card $left={datum.left} $top={datum.top}>
                                <CardTitle>{datum.head}</CardTitle>
                                <Ul>
                                    {datum.list.map(listItem => {
                                        if (typeof listItem === 'string') {
                                            return (
                                                <CardMainItem key={listItem}>
                                                    {listItem}
                                                </CardMainItem>
                                            );
                                        } else {
                                            return (
                                                <CardMainItem key={listItem[0]}>
                                                    {listItem[0]}
                                                    <Spacer $h={'0.75rem'} />
                                                    <Ul>
                                                        {listItem
                                                            .slice(1)
                                                            .map(viceItem => (
                                                                <CardViceItem
                                                                    key={
                                                                        viceItem
                                                                    }
                                                                >
                                                                    {viceItem}
                                                                </CardViceItem>
                                                            ))}
                                                    </Ul>
                                                </CardMainItem>
                                            );
                                        }
                                    })}
                                </Ul>
                            </Card>
                            <IconWrapper
                                $left={datum.icon.left}
                                $top={datum.icon.top}
                                $width={datum.icon.width}
                                $height={datum.icon.height}
                            >
                                {datum.icon.icon}
                            </IconWrapper>
                        </div>
                    );
                })}
            </CircleBox>
        );
    };

    return (
        <ContainerWithBg
            $bg={BgStar}
            $paddingTop="0"
            $paddingBottom="0"
            $bgRepeat="repeat"
            $bgSize="contain"
        >
            <ContainerWithBg
                $bg={isMobile ? MobileBgAnalytic : BgAnalyticLight}
            >
                <MainAreaWrapper>
                    <TextCentered>
                        <SubTitle $color="white">
                            Audigent&apos;s managed service team implements
                            daily campaign optimizations to drive performance
                            and achieve business objectives.
                        </SubTitle>
                    </TextCentered>
                    <MainContentBox>
                        {isMobile ? renderMobile() : renderDesktop()}
                    </MainContentBox>
                </MainAreaWrapper>
            </ContainerWithBg>
        </ContainerWithBg>
    );
}
