import styled from 'styled-components';
import AudiButton from './pages/catalog/AudiButton';
import { device } from './device';

export const ContainerWithBg = styled.div<{
    $bg?: string;
    $paddingTop?: string;
    $paddingBottom?: string;
    $bgRepeat?: string;
    $bgSize?: string;
    $margin?: string;
    $bgPosition?: string;
}>`
    background-image: url(${props => props.$bg});
    background-position: ${props => props.$bgPosition};
    background-size: ${props => props.$bgSize ?? '100% 100%'};
    background-repeat: ${props => props.$bgRepeat ?? 'no-repeat'};
    color: white;
    margin: ${props => props.$margin ?? '0'};
    padding-top: ${props => props.$paddingTop ?? '80px'};
    padding-bottom: ${props => props.$paddingBottom ?? '80px'};

    @media ${device.mobile} {
        padding-top: ${props => props.$paddingTop ?? '48px'};
        padding-bottom: ${props => props.$paddingBottom ?? '48px'};
    }
`;

export const MainAreaWrapper = styled.div<{
    $bg?: string;
    $bgSize?: string;
    $margin?: string;
    $bgPosition?: string;
    $padding?: string;
}>`
    width: 100%;
    margin: ${props => props.$margin ?? '0 auto'};
    background-image: url(${props => props.$bg});
    background-size: ${props => props.$bgSize ?? '100% 100%'};
    background-repeat: no-repeat;
    background-position: ${props => props.$bgPosition ?? 'center'};
    padding: ${props => props.$padding};

    @media ${device.laptop} {
        width: var(--firstUnit-lg-container-width, 1200px);
    }
    @media ${device.mobile} {
        padding: ${props => props.$padding ?? '0 1rem'};
    }
`;

export const Centered = styled.div<{ $column?: boolean; $margin?: string }>`
    margin: ${props => props.$margin ?? '0'};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: ${props => (props.$column ? 'column' : 'row')};

    @media ${device.mobile} {
        flex-direction: column;
    }
`;

export const FlexBox = styled.div<{
    $justifyContent?: string;
    $alignItems?: string;
    $flexDirection?: string;
    $flexWrap?: 'wrap';
    $justifyItems?: string;
    $gap?: string;
}>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: ${props => props.$flexDirection ?? 'row'};
    justify-content: ${props => props.$justifyContent ?? 'center'};
    align-items: ${props => props.$alignItems ?? 'center'};
    flex-wrap: ${props => props.$flexWrap ?? 'noWrap'};
    justify-items: ${props => props.$justifyItems ?? 'legacy'};
    gap: ${props => props.$gap ?? 'normal'};

    @media ${device.mobile} {
        flex-direction: ${props => props.$flexDirection ?? 'column'};
    }
`;

export const FlexItem = styled.div<{
    $flex?: number | string;
    $margin?: string;
}>`
    flex: ${props => props.$flex ?? 'initial'};
    margin: ${props => props.$margin ?? 0};
`;

export const GridBox = styled.div<{
    $gridTemplateColumns?: string;
    $gridGap?: number;
}>`
    display: grid;
    grid-template-columns: ${props => props.$gridTemplateColumns ?? 'none'};
    grid-template-rows: 1fr 1fr;
    grid-gap: ${props => `${props.$gridGap}px`};
`;

export const TextCentered = styled.div`
    text-align: center;
`;

export const TextAlign = styled.div`
    @media ${device.mobile} {
        text-align: center;
    }
    @media ${device.laptop} {
        text-align: start;
    }
`;

export const SectionTitle = styled.div`
    font-size: 2.75rem;
    color: white;
    line-height: 1.2;
    font-family: Renogare-Regular;

    @media ${device.mobile} {
        font-size: 1.5rem;
    }
`;

export const PrimaryText = styled.span`
    color: var(--firstUnit-primary);
    font-family: Renogare-Regular;
`;

export const SubTitle = styled.div<{
    $margin?: string;
    $color?: string;
}>`
    margin: ${props => props.$margin ?? '1.5rem 0 0'};
    font-size: 29px;
    color: ${props => props.$color ?? '#ffffffCC'};
    text-align: center;

    @media ${device.mobile} {
        font-size: 17px;
        // margin-top: 8px;
    }
`;

export const DesignatedMarginDescription = styled(SubTitle)`
    font-size: 20px;
    text-align: start;

    @media ${device.mobile} {
        text-align: center;
        font-size: 17px;
        margin: 1rem 0 0;
    }
`;

export const Spacer = styled.div<{ $w?: string; $h?: string }>`
    height: ${props => props.$h ?? '100%'};
    width: ${props => props.$w ?? '100%'};
    opacity: 0;
`;

export const Card = styled.div`
    border-radius: 16px;
    border: 1px solid grey;
    padding: var(--padding);
`;

export const GradientPrimary = styled.div`
    background: var(--firstUnit-primary-gradient);
`;

export const PaginationBox = styled(FlexBox)<{
    $mobileGap?: string;
    $margin?: string;
}>`
    height: 2.5rem;
    margin: ${props => props.$margin ?? '0'};

    @media ${device.mobile} {
        --swiper-pagination-bullet-horizontal-gap: 3px;
        gap: ${props => props.$mobileGap ?? '0.5rem'};
        margin-top: 0.75rem;
    }

    @media ${device.tablet} {
        max-width: 400px;
    }

    @media ${device.laptop} {
        max-width: 573px;
    }
`;

export const CTAButton = styled(AudiButton)<{ $width?: string }>`
    font-size: 20px;
    font-family: Renogare-Regular;
    width: ${props => props.$width ?? '20rem'};
    text-align: center;
    border-radius: 12px;
    padding: 1rem 0;
    border: 2px solid rgba(255, 255, 255, 0.2);
    background: linear-gradient(
        120deg,
        rgba(215, 224, 237, 0.16) 13.29%,
        rgba(215, 224, 237, 0.02) 100%
    );

    @media ${device.mobile} {
        padding: 0.75rem 0;
    }
`;
