import { forwardRef, useRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { device } from '../../device';
import {
    Centered,
    ContainerWithBg,
    PrimaryText,
    TextCentered,
    Spacer,
    SectionTitle,
    GradientPrimary,
} from '../../styleComponents';
import contactBg from '../../assets/images/bg-contact.png';
import TextareaIcon from '../../assets/icons/textarea.svg';
import { useIsMobile } from '../../hooks';
import { useIP } from '../../../../hooks/useIP';

type FormData = {
    name: string;
    email: string;
    message: string;
};

const Label = styled.label`
    width: 100px;
    color: white;
    opacity: 0.8;
    @media ${device.mobile} {
        width: ${props => (props.children ? '80px' : '0')};
    }
`;

const Form = styled.form`
    width: 520px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    @media ${device.mobile} {
        width: 100%;
        padding: 0 2.5rem;
    }
`;

const FormItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    @media ${device.mobile} {
        margin-bottom: 1.25rem;
    }
`;

const Input = styled.input`
    flex: 1;
    height: 40px;
    padding: 10px;
    border-radius: 12px;
    outline: none;
    font-size: 16px;
    transition: all 200ms ease-in-out;
    color: rgba(255, 255, 255, 1);
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);

    &::placeholder {
        font-size: 14px;
        color: #858585;
    }
    &:hover {
        cursor: text;
        border: 1px solid #ccaa66;
    }
    &:focus {
        border: 1px solid #ccaa66;
    }
`;

const TextArea = styled.textarea`
    flex: 1;
    height: 144px;
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    outline: none;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    transition: all 200ms ease-in-out;
    background-color: rgba(255, 255, 255, 0.1);
    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888888;
        border-radius: 12px;
        outline: 1px solid slategrey;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }
  
    &::placeholder {
        font-size: 14px;
        color: #858585;
    }
    &:hover {
        cursor: text;
        border: 1px solid #ccaa66;
    }
    &:focus {
        border: 1px solid #ccaa66;
    }
`;

const Button = styled(GradientPrimary).attrs({ as: 'button' })<{
    type?: 'submit';
}>`
    font-family: Renogare-Regular;
    width: 340px;
    height: 62px;
    margin-top: 20px;
    margin-right: 80px;
    border: none;
    border-radius: 12px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    margin-bottom: 2rem;

    @media ${device.mobile} {
        width: 100%;
        margin: 2.5rem 0 0;
        height: 58px;
    }
`;

const TextAreaWrapper = styled.div`
    flex: 1;
    position: relative;
    display: flex;
`;

const ContactUs = forwardRef(function ContactUs(_, ref) {
    const formRef = useRef<HTMLDivElement>(null);
    const isMobile = useIsMobile();
    const ipAddress = useIP();
    useImperativeHandle(
        ref,
        () => {
            return {
                scrollIntoView: () => {
                    if (formRef.current) {
                        formRef.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                        });
                    }
                },
            };
        },
        []
    );
    const onDatabaseSubmit = (data: FormData) => {
        axios
            .post(
                'https://audigent.com/operation/leads', //we have to use a full address not a relative address, otherwise the local site will go to a wrong url since we only set up the redirection for dev, staging and master site on AWS.
                {
                    ...data,
                    site_address: process.env.GATSBY_SITE_URL,
                    ip_address: ipAddress,
                    form_name: '1st Unit Contact',
                }
            )
            .then(res => {
                //console.log('Database submission: success.');
            })
            .catch(err => {
                //console.log(`error in submitting data to databse: ${err}`);
            });
    };
    const onEmailSubmit = (data: FormData) => {
        axios
            .post(
                'https://jmwtq703p5.execute-api.us-west-2.amazonaws.com/default',
                data
            )
            .then(res => {
                setTimeout(() => {
                    window.location.href = window.location.origin;
                }, 200);
            })
            .catch(err => {
                console.log(`Error in email sending: ${err}`);
            });
    };
    const handleSubmit: React.FormEventHandler<HTMLFormElement> = e => {
        e.preventDefault();
        const data = Object.fromEntries(
            new FormData(e.target as HTMLFormElement)
        ) as FormData;
        Promise.allSettled([onEmailSubmit(data), onDatabaseSubmit(data)])
            .then(res => {
                //res.forEach((res, i) => console.log(`${i}: ${res.status}`));
            })
            .catch(err => {
                err.forEach((err: any, i: any) => console.log(`${i}: ${err}`));
            });
    };
    return (
        <ContainerWithBg $bg={contactBg} ref={formRef}>
            <Centered>
                <TextCentered>
                    <SectionTitle>
                        Contact us to test drive{' '}
                        <PrimaryText>1stUnit</PrimaryText>
                    </SectionTitle>
                    <Spacer $h={'1.5rem'} />
                </TextCentered>
            </Centered>

            <Centered>
                <Form onSubmit={handleSubmit}>
                    <FormItem>
                        <Label htmlFor="name">Name</Label>
                        <Input
                            type="text"
                            name="name"
                            required
                            placeholder="Enter name"
                        />
                    </FormItem>
                    <FormItem>
                        <Label htmlFor="name">Email</Label>
                        <Input
                            type="email"
                            name="email"
                            required
                            placeholder="Enter email"
                        />
                    </FormItem>
                    <FormItem>
                        <Label htmlFor="message">Message</Label>
                        <TextAreaWrapper>
                            <TextArea
                                name="message"
                                required
                                placeholder="Enter message"
                            />
                            {isMobile && (
                                <TextareaIcon
                                    style={{
                                        position: 'absolute',
                                        right: '2px',
                                        bottom: '2px',
                                    }}
                                />
                            )}
                        </TextAreaWrapper>
                    </FormItem>
                    <FormItem>
                        <Label />
                        <Button type="submit">CONTACT US</Button>
                    </FormItem>
                </Form>
            </Centered>
        </ContainerWithBg>
    );
});

export default ContactUs;
