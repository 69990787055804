import FiveLogos from '../../assets/images/five-logos.png';
import {
    ContainerWithBg,
    DesignatedMarginDescription,
    FlexBox,
    FlexItem,
    MainAreaWrapper,
    PrimaryText,
    SectionTitle,
    TextAlign,
} from '../../styleComponents';
import BgPowered from '../../assets/images/bg-powered.png';
import MobileBgPowered from '../../assets/images/mobile-bg-powered.png';
import styled from 'styled-components';
import { device } from '../../device';
import { useIsMobile } from '../../hooks';

const ContentBox = styled(FlexBox)`
    gap: 1.375rem;

    @media ${device.mobile} {
        gap: 3rem;
    }
`;

export default function Powered() {
    const isMobile = useIsMobile();
    return (
        <ContainerWithBg $bg={isMobile ? MobileBgPowered : BgPowered}>
            <MainAreaWrapper>
                <ContentBox>
                    <FlexItem $flex={2}>
                        <TextAlign>
                            <SectionTitle>
                                Powered by the <PrimaryText>leader</PrimaryText>
                                &nbsp;in first-party data activation, curation,
                                and identity
                            </SectionTitle>
                            <DesignatedMarginDescription $margin="1.5rem 4rem 0 0">
                                Audigent's one-of-a-kind offering features both
                                campaign strategy and media activation services,
                                eliminating the need to rely on third-party data
                                and antiquated technology. By introducing
                                data-driven creative capabilities, Audigent
                                delivers a new level of service to our trusted
                                partners including quick turnarounds, fast
                                approval times, detailed insights, and
                                reporting.
                            </DesignatedMarginDescription>
                        </TextAlign>
                    </FlexItem>
                    <FlexItem $flex={3}>
                        <img src={FiveLogos} alt="five-logo" />
                    </FlexItem>
                </ContentBox>
            </MainAreaWrapper>
        </ContainerWithBg>
    );
}
