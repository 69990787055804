import { useState, useRef } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperType } from 'swiper';
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';
import AudiButton from './AudiButton';
import {
  Centered,
  ContainerWithBg,
  FlexBox,
  MainAreaWrapper,
  PrimaryText,
  TextCentered,
  PaginationBox,
  CTAButton,
  SubTitle,
} from '../../styleComponents';

import BgCatalog from '../../assets/images/bg-catalog.png';
import BgCatalogRequestDemo from '../../assets/images/bg-catalog-request-demo.png';
import MobileBgCatalog from '../../assets/images/mobile-bg-catalog.png';
import MobileBgCatalogMenu from '../../assets/images/mobile-bg-catalog-menu.png';

import auto3DVideoCube from '../../assets/gifs/automobile/automobile-3d-video-cube.gif';
import autoClickToMap from '../../assets/gifs/automobile/automobile-click-to-map.gif';
import autoFancyCarousel from '../../assets/gifs/automobile/automobile-fancy-carousel.gif';
import autoFlow from '../../assets/gifs/automobile/automobile-flow.gif';
import autoGlueStick from '../../assets/gifs/automobile/automobile-gluestick.gif';
import autoImaVideo from '../../assets/gifs/automobile/automobile-ima-video.gif';
import autoParallax from '../../assets/gifs/automobile/automobile-parallax.gif';
import autoRoundabout from '../../assets/gifs/automobile/automobile-roundabout.gif';
import autoSlider from '../../assets/gifs/automobile/automobile-slider.gif';
import autoExhibitionTimer from '../../assets/gifs/automobile/automobile-exhibition-timer.gif';
import autoVideoBillboard from '../../assets/gifs/automobile/automobile-video-billboard.gif';
import autoVideoWall from '../../assets/gifs/automobile/automobile-video-wall.gif';
import autoMegaShopper from '../../assets/gifs/automobile/automobile-mega-shopper.gif';
import auto3DExpandableCarousel from '../../assets/gifs/automobile/automobile-3d-expandable-carousel.gif';

import bsfiCountdown from '../../assets/gifs/bsfi/bsfi-countdown.gif';
import bsfiGiftBox from '../../assets/gifs/bsfi/bsfi-gift-box.gif';
import bsfiSwipeToInstall from '../../assets/gifs/bsfi/bsfi-swipe-to-install.gif';
import bsfiStormIn from '../../assets/gifs/bsfi/bsfi-storm-in.gif';
import bsfiQuizNWin from '../../assets/gifs/bsfi/bsfi-quiz-n-win.gif';
import bsfiGlideSignUp from '../../assets/gifs/bsfi/bsfi-glide-sign-up.gif';
import bsfiScratchToReveal from '../../assets/gifs/bsfi/bsfi-scratch-to-reveal.gif';

import realCarousel from '../../assets/gifs/real/real-carousel.gif';
import realGlideGallery from '../../assets/gifs/real/real-glide-gallery.gif';
import realSurvey from '../../assets/gifs/real/real-survey.gif';

import consumerHotSpot from '../../assets/gifs/consumer/consumer-hotspot.gif';
import consumerImageDrift from '../../assets/gifs/consumer/consumer-image-drift.gif';
import consumerInterScroller from '../../assets/gifs/consumer/consumer-interscroller.gif';
import consumerLampDrag from '../../assets/gifs/consumer/consumer-lamp-drag.gif';
import consumerQuizUp from '../../assets/gifs/consumer/consumer-quiz-up.gif';

import educationFerrisWheel from '../../assets/gifs/education/education-ferris-wheel.gif';
import educationSurveyBuilder from '../../assets/gifs/education/education-survey-builder.gif';
import educationVideoCube from '../../assets/gifs/education/education-video-cube.gif';

import retailAddToCalendar from '../../assets/gifs/retail/retail-add-to-calendar.png';
import retailFading from '../../assets/gifs/retail/retail-fading.gif';
import retailFlashSale from '../../assets/gifs/retail/retail-flash-sale.gif';
import retailInstaVideo from '../../assets/gifs/retail/retail-insta-video.gif';
import retailInsta from '../../assets/gifs/retail/retail-insta.png';
import retailProductShowcase from '../../assets/gifs/retail/retail-product-showcase.gif';
import retailSnow from '../../assets/gifs/retail/retail-snow.gif';
import retailTheater from '../../assets/gifs/retail/retail-theater.gif';
import retailVideoShopper from '../../assets/gifs/retail/retail-video-shopper.gif';
import retailVoteClick from '../../assets/gifs/retail/retail-vote-click.gif';

import commerceCatalog from '../../assets/gifs/commerce/e-commerce-catalog.gif';
import commerceSwipeToLike from '../../assets/gifs/commerce/e-commerce-swipe-to-like.gif';
import commerce3DDisplayBoard from '../../assets/gifs/commerce/e-commerce-3d-display-board.gif';

import entertainmentFadeIn from '../../assets/gifs/entertainment/entertainment-fade-in.gif';
import entertainmentSpinCube from '../../assets/gifs/entertainment/entertainment-spin-cube.gif';
import entertainmentVideoImage from '../../assets/gifs/entertainment/entertainment-video-image-interscroller.gif';
import entertainmentVideoInterscroller from '../../assets/gifs/entertainment/entertainment-video-interscroller.gif';

import fmcgImageStrip from '../../assets/gifs/fmcg/fmcg-image-strip.gif';
import fmcgScratchToWin from '../../assets/gifs/fmcg/fmcg-scratch-to-win.gif';

import travelFeatureCards from '../../assets/gifs/travel/travel-feature-cards.gif';
import travelFeatureCardsStatic from '../../assets/gifs/travel/travel-feature-cards-half-page.png';
import travelTransition from '../../assets/gifs/travel/travel-transition.gif';

import { device } from '../../device';
import { useIsMobile } from '../../hooks';
import ArrowLeft from '../../assets/icons/arrow-left.svg';
import ArrowRight from '../../assets/icons/arrow-right.svg';
import { debounce } from 'lodash';

type Props = {
  readonly handleClickContact: () => void;
};

const industries = {
  Automotive: [
    auto3DExpandableCarousel,
    commerce3DDisplayBoard,
    autoSlider,
    autoVideoBillboard,
    realCarousel,
    autoVideoWall,
    autoMegaShopper,
    autoParallax,
    autoClickToMap,
    autoFlow,
    autoGlueStick,
    auto3DVideoCube,
    autoExhibitionTimer,
    autoRoundabout,
  ],
  'Consumer Electronics': [
    consumerInterScroller,
    consumerLampDrag,
  ],
  CPG: [
    fmcgImageStrip,
    consumerQuizUp,
    consumerImageDrift,
    fmcgScratchToWin,
  ],
  Education: [
    educationVideoCube,
    educationSurveyBuilder,
    educationFerrisWheel,
  ],
  Entertainment: [
    entertainmentFadeIn,
    retailAddToCalendar,
    entertainmentVideoInterscroller,
    entertainmentVideoImage,
    entertainmentSpinCube,
    bsfiScratchToReveal,
  ],
  'Financial Services': [
    bsfiGiftBox,
    bsfiCountdown,
    bsfiGlideSignUp,
    bsfiStormIn,
    retailVoteClick,
    bsfiSwipeToInstall,
    bsfiQuizNWin,
  ],
  Grocery: [
    retailVideoShopper,
    retailTheater,
    commerceCatalog
  ],
  'Real Estate': [
    realGlideGallery,
    realSurvey,
  ],
  'Retail & Fashion': [
    commerceSwipeToLike,
    autoFancyCarousel,
    retailProductShowcase,
    retailFlashSale,
    retailFading,
    retailInstaVideo,
    retailInsta,
    consumerHotSpot,
    retailSnow,
  ],
  'Travel & Tourism': [
    travelTransition,
    travelFeatureCards,
    travelFeatureCardsStatic,
  ],
} as const;

const Title = styled.div`
  margin-top: 82px;
  text-align: center;
  font-size: 58px;
  line-height: 1.2;
  font-family: Renogare-Regular;

  @media ${device.mobile} {
    font-size: 1.5rem;
    margin-top: 0px;
  }
`;

const MenuTitle = styled.div`
  margin-top: 4rem;
  margin-bottom: 2.25rem;
  border-radius: 0.75rem;
  width: 314px;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to right, #ffffff00, #ffffff1a, #ffffff00);
`;

const Text = styled(TextCentered)`
  font-size: 1.25rem;

  @media ${device.mobile} {
    padding: 0 1rem;
    font-size: 0.875rem;
  }
`;

const CatalogFlexBox = styled(FlexBox)`
  max-width: 1200px;
  margin: 1.5rem auto 0;
  gap: 1.25rem;
  color: var(--grey-white);

  @media ${device.mobile} {
    gap: 1rem;
  }
`;

const FixedPhone = styled.div`
  pointer-events: none;
  position: absolute;
  height: 532px;
  left: calc(50% - 156px);
  top: 0;
  z-index: 1;
  width: 312px;
  border: 14px solid #ffffff3c;
  border-radius: 44px;

  @media ${device.mobile} {
    height: 480px;
    width: 72%;
    left: calc(14%);
  }
`;

const NavButtonBase = styled(Centered) <{ $disabled?: boolean }>`
  position: absolute;
  cursor: ${props => (props.$disabled ? 'not-allowed' : 'pointer')};
  z-index: 2;
  width: 50px;
  height: 50px;
  border-radius: 16px;
  border: 1px solid #ffffff3c;
  background: ${props => (props.$disabled ? '#f2f2f255' : '#ffffff19')};
  box-shadow: 0px 0px 14.4px 0px rgba(223, 241, 254, 0.45) inset;
  ${props => !props.$disabled && `
    &:hover {
      --gradient-primary-color: #e27285;
      --gradient-secondary-color: #9874e5;
    }
  `}
`;

const PrevButton = styled(NavButtonBase)`
  left: 2rem;
  top: calc(50% - 25px);
`;
const NextButton = styled(NavButtonBase)`
  right: 2rem;
  top: calc(50% - 25px);
`;

const MIN_AMOUNT_FOR_LOOP = 4

export default function Catalog({ handleClickContact }: Props) {
  const isMobile = useIsMobile();
  const pageRef = useRef(null);
  const [prevDisabled, setPrevDisabled] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(false);
  const swiperRef = useRef<SwiperType | null>(null);
  const [selectedIndustry, setSelectedIndustry] = useState(Object.keys(industries)[0]);

  const currentIndustry = industries[selectedIndustry as 'Education'];

  const handleSlideChange = debounce((swiper: SwiperClass) => {
    if (currentIndustry.length < MIN_AMOUNT_FOR_LOOP) {
      setPrevDisabled(swiper.realIndex === 0);
      setNextDisabled(swiper.realIndex === (currentIndustry.length - 1));
    } else {
      setPrevDisabled(false)
      setNextDisabled(false)
    }
  }, 10)

  return (
    <ContainerWithBg $bg={isMobile ? MobileBgCatalog : BgCatalog} $paddingTop={isMobile ? '120px' : undefined} $paddingBottom={'0'} $margin="0 0 -2px">
      <MainAreaWrapper>
        <Title>
          Unlock the Performance of&nbsp;
          <PrimaryText>Interactive & Rich Media Advertising Units</PrimaryText>
        </Title>
        <SubTitle>
          With 360+ high impact elements, the creative possibilities with 1stUnit are endless. From product carousels to
          interactivity to video walls and more, 1stUnit provides a robust platform for brands to engage in interactive
          storytelling.
        </SubTitle>
      </MainAreaWrapper>

      <MainAreaWrapper $padding={isMobile ? '2rem 0 0' : '60px 0 0'}>
        <Text>Select an industry to view a sample of the best performing units.</Text>
        <Centered>
          <CatalogFlexBox $flexDirection="row" $flexWrap="wrap">
            {Object.keys(industries).map((industry) => {
              return (
                <AudiButton
                  key={industry}
                  type={industry === selectedIndustry ? 'colorful' : ''}
                  onClick={(val) => {
                    setSelectedIndustry(val);
                    swiperRef.current?.el.scrollIntoView({ behavior: 'smooth', block: 'center' });
                  }}
                >
                  {industry}
                </AudiButton>
              );
            })}
          </CatalogFlexBox>
        </Centered>
      </MainAreaWrapper>

      {Object.entries(industries).map(([key, slides]) => {
        const _props = {
          effect: 'coverflow',
          grabCursor: true,
          centeredSlides: true,
          initialSlide: 1,
          slidesPerView: 'auto' as const,
          coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
            slideShadows: false,
          },
          autoplay:
            isMobile
              ? true
              : {
                delay: 6000,
                pauseOnMouseEnter: true,
                disableOnInteraction: true,
              },
          pagination:
            isMobile
              ? {
                el: pageRef.current,
                type: 'bullets',
                bulletClass: `swiper-pagination-bullet audi-swiper-pagination-bullet`,
                bulletActiveClass: 'audi-swiper-bullet-active',
                clickable: true,
              }
              : false,
          modules: [EffectCoverflow, Pagination, Autoplay],
          className: "audi-creative-swiper",
          lazyLoading: true,
          onInit: (swiper: SwiperType) => {
            swiperRef.current = swiper;
          },
          onSlideChange: handleSlideChange
        } as any;
        if (selectedIndustry !== key) return null
        return (
          <Swiper
            key={key}
            {..._props}
            spaceBetween={slides.length >= MIN_AMOUNT_FOR_LOOP ? 1 : 0}
            loop={slides.length >= MIN_AMOUNT_FOR_LOOP}
          >
            {slides.map((slide) => {
              return (
                <SwiperSlide className="audi-creative-slide" key={slide}>
                  <img src={slide} alt="slide_image" />
                </SwiperSlide>
              );
            })}
            {!isMobile && (
              <>
                {!prevDisabled && <PrevButton onClick={() => swiperRef.current?.slidePrev()} $disabled={prevDisabled}>
                  <ArrowLeft />
                </PrevButton>}
                {!nextDisabled && <NextButton onClick={() => swiperRef.current?.slideNext()} $disabled={nextDisabled}>
                  <ArrowRight />
                </NextButton>}
              </>
            )}
            <FixedPhone />
          </Swiper>
        )
      })}

      <PaginationBox $flexDirection="row" $mobileGap={'0'} $alignItems={'center'} ref={pageRef} style={!isMobile ? { height: 0 } : {}} />
      <ContainerWithBg $bg={isMobile ? MobileBgCatalogMenu : BgCatalogRequestDemo} $paddingTop={'0'}>
        <Centered $margin={isMobile ? '2rem 2rem 0' : '0'}>
          <CTAButton onClick={handleClickContact}>REQUEST DEMO</CTAButton>
        </Centered>
      </ContainerWithBg>
    </ContainerWithBg>
  );
}
