import { useCallback, useEffect, useRef, useState } from 'react';
import {
    Centered,
    MainAreaWrapper,
    ContainerWithBg,
    PrimaryText,
    SectionTitle,
    TextCentered,
    Spacer,
    CTAButton,
    SubTitle,
} from '../../styleComponents';
import styled from 'styled-components';
import BgCompare from '../../assets/images/bg-compare.png';
import BgLightCompare from '../../assets/images/bg-compare-light.png';
import compareMid from '../../assets/images/compare-table@3x.png';
import { useIsMobile } from '../../hooks';
import Left from '../../assets/images/compare-title.png';
import Right from '../../assets/images/compare-content.png';
import GestureGif from '../../assets/images/gesture-swipe-left.gif';
const MobileImgBox = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
`;
const TitleBox = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
`;

const TitleImg = styled.img`
    object-fit: contain;
    height: 100% !important;
`;

const ContentBox = styled.div`
    flex: 1;
    margin-top: 0.2px;
    position: relative;
    margin-left: 126.3px;
    overflow-x: scroll;
`;
const ContentImg = styled.img`
    margin-left: 0rem;
    left: 0;
    object-fit: contain;
    height: 30rem !important;
    max-width: initial !important;
`;

const GestureGuidance = styled.img`
    right: 10%;
    top: 40%;
    position: absolute;
    pointer-events: none;
`;

type Props = {
    readonly handleClickContact: () => void;
};
const isGestureHidden = !!(
    typeof window !== 'undefined' && window.localStorage.getItem('hideGesture')
);

export default function Compare({ handleClickContact }: Props) {
    const isMobile = useIsMobile();
    const touchStartRef = useRef({ clientX: 0 });
    const [elementDiv, setElementDiv] = useState<HTMLDivElement | null>(null);
    const [gestureHidden, setGestureHidden] = useState(isGestureHidden);

    const handleImgLoad = useCallback((node: HTMLDivElement) => {
        setElementDiv(node);
    }, []);

    useEffect(() => {
        const handleTouchStart = (e: TouchEvent) => {
            touchStartRef.current.clientX = e.touches[0].clientX;
        };
        const handleTouch = (e: TouchEvent) => {
            if (gestureHidden) return;
            const currentClientX = e.touches[0].clientX;
            if (Math.abs(touchStartRef.current.clientX - currentClientX) > 50) {
                setGestureHidden(true);
                typeof window !== 'undefined' &&
                    window.localStorage.setItem('hideGesture', 'true');
            }
        };
        if (elementDiv) {
            elementDiv.addEventListener('touchstart', handleTouchStart);
            elementDiv.addEventListener('touchmove', handleTouch);
        }

        return () => {
            elementDiv?.removeEventListener('touchstart', handleTouchStart);
            elementDiv?.removeEventListener('touchmove', handleTouch);
        };
    }, [elementDiv]);

    return (
        <ContainerWithBg $bg={BgCompare} $paddingBottom={'0'} $paddingTop={'0'}>
            <ContainerWithBg $bg={BgLightCompare}>
                <MainAreaWrapper>
                    <Centered $column>
                        <TextCentered>
                            <SectionTitle>
                                How <PrimaryText>Audigent</PrimaryText> compares
                                to the competition
                            </SectionTitle>
                            <SubTitle>
                                A comprehensive full-suite solution for
                                creative, curation, identity and activation.
                            </SubTitle>
                        </TextCentered>
                    </Centered>
                    <Spacer $h={isMobile ? '1.5rem' : '3.75rem'} />

                    {isMobile ? (
                        <MobileImgBox>
                            <TitleBox>
                                <TitleImg src={Left} alt="title" />
                            </TitleBox>
                            <ContentBox ref={handleImgLoad}>
                                <ContentImg
                                    src={Right}
                                    alt="compare with competition"
                                />
                            </ContentBox>
                            {!gestureHidden && (
                                <GestureGuidance
                                    src={GestureGif}
                                    width={120}
                                    alt="gesture guidance"
                                />
                            )}
                        </MobileImgBox>
                    ) : (
                        <img src={compareMid} alt="compare table" />
                    )}

                    <Centered $margin={'1.5rem 0 0'}>
                        <CTAButton
                            onClick={handleClickContact}
                            $width={isMobile ? '80vw' : '25rem'}
                        >
                            CONNECT WITH OUR TEAM
                        </CTAButton>
                    </Centered>
                </MainAreaWrapper>
            </ContainerWithBg>
        </ContainerWithBg>
    );
}
