import { useCallback, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { PhotoSlider } from 'react-photo-view';
import styled from 'styled-components';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperType } from 'swiper';
import { Autoplay, Pagination, EffectFade, Thumbs, FreeMode } from 'swiper/modules';
import {
  MainAreaWrapper,
  ContainerWithBg,
  PrimaryText,
  SectionTitle,
  SubTitle,
  TextCentered,
  Spacer,
  FlexBox,
  FlexItem,
  PaginationBox,
} from '../../styleComponents';
import { useIsMobile } from '../../hooks';
import Performance from '../../assets/icons/performance.svg';
import Report from '../../assets/icons/report.svg';
import Bulb from '../../assets/icons/bulb.svg';
import ActivePerformance from '../../assets/icons/performance-active.svg';
import ActiveReport from '../../assets/icons/report-active.svg';
import ActiveBulb from '../../assets/icons/bulb-active.svg';
import reporting1 from '../../assets/images/reporting-weekly.png';
import reporting2 from '../../assets/images/reporting-granular.png';
import reporting3 from '../../assets/images/reporting-informed.png';
import reportingBg from '../../assets/images/bg-reporting.png';
import reportingLightBg from '../../assets/images/bg-reporting-light.png';
import { device } from '../../device';
import PreviewToolbar from '../../components/photoPreviewToolbar';
import Expand from '../../assets/icons/expand.svg';

const resources = [
  {
    icon: <Performance />,
    activeIcon: <ActivePerformance />,
    text: 'Weekly deliverable to track pacing & all key performance metrics',
    slideImage: reporting1,
  },
  {
    icon: <Report />,
    activeIcon: <ActiveReport />,
    text: 'Granular reporting to include tactics, strategies, creatives, audiences & more',
    slideImage: reporting2,
  },
  {
    icon: <Bulb />,
    activeIcon: <ActiveBulb />,
    text: `Informed decision making & optimizations based on learned insights backed by Hadron ID \u2122`,
    slideImage: reporting3,
  },
];

const SlideTextBox = styled.div`
  color: white;
  font-size: 16px;
  margin-left: 12px;
`;

const ImageSwiperSlideContainer = styled(FlexItem)`
  display: flex;
  position: relative;
  border: 14px solid #ffffff3c;
  border-radius: 44px;

  @media ${device.mobile} {
    width: 100%;
    border: 8px solid #ffffff3c;
    border-radius: 32px;
  }

  @media ${device.tablet} {
    max-width: 400px;
  }

  @media ${device.laptop} {
    max-width: 573px;
  }
`;

const ExpandBox = styled.div`
  position: absolute; 
  right: 8px;
  top: 8px;
  z-index: 9;
  cursor: pointer;

  &:hover {

  }
`

export default function Reporting() {
  const isMobile = useIsMobile();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [bigImageIndex, setBigImageIndex] = useState<number>(0);
  const [bigImageVisible, setBigImageVisible] = useState<boolean>(false);
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);
  const pageRef = useRef(null);
  const imageSwiperRef = useRef<SwiperRef>(null);


  const handleSlideChange = useCallback(() => {
    setActiveIndex(imageSwiperRef.current?.swiper.realIndex ?? 0);
  }, []);

  return (
    <ContainerWithBg $bg={reportingBg} $paddingBottom={'0'} $paddingTop={'0'}>
      <ContainerWithBg $bg={reportingLightBg}>
        <MainAreaWrapper>
          <TextCentered>
            <SectionTitle>
              <PrimaryText>Reporting,</PrimaryText> insights and measurement
            </SectionTitle>
            <SubTitle>
              Audigent provides comprehensive visual reporting across all channels in a single, unified report to unlock
              insights with precise efficiency.
            </SubTitle>
          </TextCentered>
        </MainAreaWrapper>
        <Spacer $h={isMobile ? '3rem' : '6rem'} />
        <PhotoSlider
          loop
          toolbarRender={PreviewToolbar}
          images={resources.map((item) => ({ src: item.slideImage, key: item.text }))}
          visible={bigImageVisible}
          onClose={() => setBigImageVisible(false)}
          index={bigImageIndex}
          onIndexChange={setBigImageIndex}
        />
        <MainAreaWrapper>
          <FlexBox
            style={isMobile ? {} : { height: '500px' }}
            $alignItems="normal"
            $flexDirection={isMobile ? 'column-reverse' : 'row'}
            $gap={isMobile ? '0' : '3.375rem'}
          >
            <FlexItem $flex={9}>
              <Swiper
                onSwiper={setThumbsSwiper}
                direction="vertical"
                spaceBetween={isMobile ? 20 : 48}
                slidesPerView={isMobile ? 'auto' : 3}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Thumbs]}
                className="audi-report-swiper"
              >
                {resources.map((item, index) => {
                  return (
                    <SwiperSlide
                      className="audi-report-swiper-slide"
                      key={typeof item.text === 'string' ? item.text : ReactDOMServer.renderToString(item.text)}
                    >
                      <div>{activeIndex === index ? item.activeIcon : item.icon}</div>
                      <SlideTextBox>{item.text}</SlideTextBox>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </FlexItem>
            <FlexItem $flex={isMobile ? 'initial' : 11}>
              <FlexBox
                $flexDirection="column"
                $justifyContent="normal"
                $alignItems={'stretch'}
                style={{ height: '100%' }}
              >
                <ImageSwiperSlideContainer $flex={1}>
                  <ExpandBox onClick={() => {
                    setBigImageIndex(activeIndex)
                    setBigImageVisible(true)
                  }}>
                    <Expand />
                  </ExpandBox>
                  <Swiper
                    ref={imageSwiperRef}
                    onSlideChange={handleSlideChange}
                    modules={[Autoplay, Pagination, FreeMode, Thumbs, EffectFade]}
                    loop={true}
                    effect={'fade'}
                    spaceBetween={32}
                    centeredSlides={true}
                    autoplay={{
                      delay: 6000,
                      pauseOnMouseEnter: true,
                    }}
                    speed={500}
                    pagination={{
                      el: pageRef.current,
                      type: 'bullets',
                      bulletClass: `swiper-pagination-bullet audi-swiper-pagination-bullet`,
                      bulletActiveClass: 'audi-swiper-bullet-active',
                      clickable: true,
                    }}
                    thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                  >
                    {resources.map((item, index) => {
                      return (
                        <SwiperSlide key={index} style={{ background: 'transparent' }} className="audi-report-image-slide">
                          <img src={item.slideImage} alt="reporting slides" className="audi-report-slide-img" />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </ImageSwiperSlideContainer>

                <PaginationBox $flexDirection="row" $alignItems={isMobile ? 'flex-start' : 'flex-end'} ref={pageRef} />
              </FlexBox>
            </FlexItem>
          </FlexBox>
        </MainAreaWrapper>
      </ContainerWithBg>
    </ContainerWithBg>
  );
}
